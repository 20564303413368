import React from 'react'
import styled from 'styled-components'

import {
  BsBuildingsFill,
  BsFillPeopleFill,
  BsFillSunFill,
  BsTranslate,
  BsCurrencyExchange,
  BsClockFill,
  BsRulers,
} from '../../utils/GetIcons'
import { Scrollbar } from '../common/Scrollbar'

const FactList = styled.div`
  ${Scrollbar}
  display: flex;
  flex-direction: row;
  height: min-content;
  margin: 0 auto;
  overflow-x: auto;
  padding: 0.5rem;
  scroll-snap-type: x mandatory;
  width: 100%;
  &::-webkit-scrollbar {
    border-radius: 0;
    height: 0.5rem;
  }
  @media (min-width: 980px) {
    bottom: 0;
    position: absolute;
  }
`

const FactWrapper = styled.div`
  background-color: ${props => props.theme.greyscale100secondary800};
  border-radius: var(--border-radius-2);
  box-sizing: border-box;
  box-shadow: var(--shadow-tight);
  display: flex;
  flex-direction: column;
  height: 100%;
  list-style: none;
  position: relative;
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  transition: background-color 300ms ease-in-out;
  white-space: nowrap;
  scroll-snap-align: start;
  scroll-margin-inline: 0.5rem; /* Set scroll margin to match the margin of the element */
`

const FactTitle = styled.h3`
  color: ${props => props.theme.black400greyscale300};
  font: 700 var(--fontSize-1) / 1.5 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-3);
  margin: 0;
  margin-right: 2.5rem;
  transition: color 300ms ease-in-out;
`

const FactSubtitle = styled.h4`
  color: ${props => props.theme.black400greyscale300};
  font: 500 var(--fontSize-0) / 2 var(--font-openSans);
  letter-spacing: var(--letterSpacing-1);
  margin: 0;
  transition: color 300ms ease-in-out;
`

const IconContainer = styled.div`
  align-self: center;
  display: flex;
  font-size: 1rem;
  color: ${props => props.theme.primary700to300};
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`

interface Props {
  facts: any
  type: string
}

export default function Facts({ facts, type }: Props): JSX.Element {
  const capital = facts.capital
  const currency = facts.currency
  const language = facts.language
  const population = facts.population
  const size = facts.size
  const timezone = facts.timezone
  const weather = facts.weather

  const getTimezone = (zone: string): string => {
    const offset = parseInt(zone)
    const date = new Date()
    const currentTime = new Date(date.getTime() + 3600000 * offset).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
    return currentTime
  }

  const getIcon = (type: string): any => {
    switch (type) {
      case 'Currency':
        return <BsCurrencyExchange />
      case 'Timezone':
        return <BsClockFill />
      case 'Language':
        return <BsTranslate />
      case 'Capital City':
        return <BsBuildingsFill />
      case 'Population':
        return <BsFillPeopleFill />
      case 'Size':
        return <BsRulers />
      case 'Best Weather':
        return <BsFillSunFill />
      default:
        return null
    }
  }

  const getFactSubtitle = (factType: string, data: any): JSX.Element | string => {
    switch (factType) {
      case 'Currency':
        return (
          <>
            <b>{data.name}</b> {data.symbol} ({data.code})
          </>
        )
      case 'Language':
        return (
          <>
            <b>{data}</b>. Hello: <i>{language.hello}</i>. Thank You: <i>{language.thanks}</i>
          </>
        )
      case 'Timezone':
        return (
          <>
            <b>{data}</b>. Time Now: {getTimezone(data.charAt(data.length - 1))}
          </>
        )
      default:
        return typeof data === 'string' ? data : ''
    }
  }

  const getFact = (factType: string, data: any): JSX.Element => {
    return (
      <FactWrapper>
        <IconContainer>{getIcon(factType)}</IconContainer>
        <FactTitle>{factType}</FactTitle>
        <FactSubtitle>{getFactSubtitle(factType, data)}</FactSubtitle>
      </FactWrapper>
    )
  }

  return (
    <FactList>
      {Boolean(language) && getFact('Language', language.name)}
      {Boolean(timezone) && getFact('Timezone', timezone)}
      {Boolean(currency) && getFact('Currency', currency)}
      {Boolean(weather) && getFact('Best Weather', weather)}
      {Boolean(capital) && getFact('Capital City', capital)}
      {Boolean(population) && getFact('Population', population)}
      {Boolean(size) && getFact('Size', size)}
    </FactList>
  )
}
