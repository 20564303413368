import React, { lazy } from 'react'
import { graphql } from 'gatsby'

import Bio from '../components/elements/bio'
import Layout from '../components/layouts/layout'
import Seo from '../components/seo'
import DestHeader from '../components/sections/headers/DestHeader'
import Facts from '../components/sections/Facts'
import addSuspense from '../utils/AddSuspense'
import { styled } from 'styled-components'

const EventHighlight = lazy(async () => await import('../components/sections/EventsWrapper'))
const CarouselSection = lazy(async () => await import('../components/sections/CarouselSection'))

const ContentContainer = styled.div`
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`

interface Props {
  data: any
  location: any
  pageContext: any
}

export default function countryPage({ data, location, pageContext }: Props): JSX.Element {
  const { country } = pageContext
  const activeContinent = data.allDataLocationsJson.nodes[0]
  const activeCountry = activeContinent.countries.find((newCountry: any) => newCountry.name === country)
  const { nodes } = data.allMarkdownRemark

  if (nodes.length === 0 || country === undefined) {
    return (
      <Layout location={location}>
        <Bio />
        <p>Error. No destinations found.</p>
      </Layout>
    )
  }

  const facts = activeCountry?.facts
  const posts = nodes.filter(
    (node: any) =>
      node.frontmatter?.type?.toLowerCase() === 'article' &&
      node.frontmatter?.country?.toLowerCase() === country?.toLowerCase()
  )
  const routes = nodes.filter(
    (node: any) => node.frontmatter?.type?.toLowerCase() === 'route' && node.frontmatter?.countries?.includes(country)
  )
  const events = nodes.filter(
    (node: any) =>
      node.frontmatter?.type?.toLowerCase() === 'event' &&
      node.frontmatter?.country?.toLowerCase() === country?.toLowerCase()
  )

  return (
    <Layout location={location}>
      <div style={{ position: 'relative' }}>
        <DestHeader title={country} description={activeCountry?.description} image={activeCountry?.image} />
        {Boolean(facts) && facts.length !== 0 && <Facts facts={facts} type="mini" />}
      </div>
      <ContentContainer>
        {Boolean(events) && events.length !== 0 && addSuspense(<EventHighlight posts={events} />)}
        {Boolean(posts) && posts.length !== 0 && addSuspense(<CarouselSection type={'ARTICLE'} posts={posts} />)}
        {Boolean(routes) && routes.length !== 0 && addSuspense(<CarouselSection type={'ROUTE'} posts={routes} />)}
      </ContentContainer>
    </Layout>
  )
}

export function Head({ location }: any): JSX.Element {
  return (
    <Seo title="Countries" location={location} description={'Find the best locations around the world by country'} />
  )
}

export const pageQuery = graphql`
  query countryQuery($country: String) {
    site {
      siteMetadata {
        title
      }
    }
    imageSharp {
      gatsbyImageData
      imageMask {
        base64
      }
    }
    allDataLocationsJson(filter: { countries: { elemMatch: { name: { eq: $country } } } }) {
      nodes {
        id
        title
        description
        countries {
          name
          description
          facts {
            capital
            currency {
              name
              symbol
              code
            }
            nationaldish
            language {
              name
              hello
              thanks
            }
            population
            size
            timezone
            weather
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          id
          date(formatString: "MMMM DD, YYYY")
          startdate(formatString: "MMMM DD, YYYY")
          enddate(formatString: "MMMM DD, YYYY")
          title
          aka
          description
          type
          subtype
          duration
          city
          country
          countries
          continent
          hero_image_credit_text
          hero_image_credit_link
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 700, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
